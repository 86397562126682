import React from "react";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";

import { updateAccountSettings } from "@frontend/api/account.service";
import { loadFolders } from "@frontend/api/dashboard.service";
import { getAllNotifications } from "@frontend/api/notifications.service";
import { notificationError } from "@frontend/components/notification";
import { OverdueInvoiceModal } from "@frontend/components/overdue-invoice-modal/overdue-invoice-modal";
import { DashboardDropzone } from "@frontend/containers/dashboard/dashboard-dropzone/dashboard-dropzone";
import { DashboardSidebar } from "@frontend/containers/dashboard/dashboard-sidebar/dashboard-sidebar";
import { UploadQueue } from "@frontend/containers/dashboard/upload-queue/upload-queue";
import { TopBannerContainer } from "@frontend/containers/top-banner/top-banner-container";
import { useAuthProvider } from "@frontend/contexts/auth.context";
import { NavProvider } from "@frontend/contexts/nav.context";

import { useAccounts } from "@core/hooks/use-accounts";
import { useAnalyticsWithAuth } from "@core/hooks/use-analytics-with-auth";
import { useDashboard } from "@core/hooks/use-dashboard";
import { useModal } from "@core/hooks/use-modal";
import { usePlan } from "@core/hooks/use-plan";
import { usePlanPermissions } from "@core/hooks/use-plan-permissions";
import { SublyPlan } from "@core/interfaces/billing";
import { ModalType } from "@core/interfaces/modal-type";
import { accountStore } from "@core/state/account";
import { VirtualFolderId } from "@core/state/dashboard/dashboard.store";
import { uploadQuery } from "@core/state/upload";

export const DashboardLayout = () => {
  const [showInvoiceModal, hideInvoiceModal] = useModal(ModalType.OverdueInvoiceModal);

  const { hasPaymentOverdue } = usePlan();
  const { currentAccount, settings, isLoaded } = useAccounts();
  const { hasShownOverduePaymentPopUp, setHasShownOverduePaymentPopUp, setHasUserClosedOverduePaymentPopUp } =
    useAuthProvider();
  const { trackEventWithAuth } = useAnalyticsWithAuth();

  const onCloseOverduePaymentPopup = () => setHasUserClosedOverduePaymentPopUp(true);

  const { selectedFolderId } = useDashboard();

  React.useEffect(() => {
    if (Boolean(hasShownOverduePaymentPopUp)) {
      return;
    }

    if (hasPaymentOverdue) {
      notificationError(
        <>
          <p className="tw-mb-0">
            It seems that the latest payment hasn't been processed. <br />
            Please{" "}
            <span
              className="tw-text-black tw-underline"
              onClick={() => {
                toast.dismiss("payment-overdue");
                showInvoiceModal(<OverdueInvoiceModal closeAlert={hideInvoiceModal} />);
              }}
            >
              click here to confirm payment
            </span>{" "}
            before accessing your Subly account.
          </p>
        </>,
        {
          toastId: "payment-overdue",
          closeButton: true,
          autoClose: false,
          onClose: onCloseOverduePaymentPopup,
          closeOnClick: false
        }
      );

      setHasShownOverduePaymentPopUp(true);
    }
  });

  // Track the first time a user has seen the Dashboard or goes to the Dashboard
  React.useEffect(() => {
    if (!isLoaded || !settings) {
      return;
    }

    if (settings.hasSeenDashboard) {
      trackEventWithAuth("User in dashboard");
      return;
    }

    const track = async () => {
      accountStore.updateAccountSettings({ hasSeenDashboard: true });
      trackEventWithAuth("First time in Dashboard");
      await updateAccountSettings({ hasSeenDashboard: true });
    };

    track();
  }, [isLoaded]);

  React.useEffect(() => {
    if (!currentAccount?.accountId) {
      return;
    }
    getAllNotifications();
  }, []);

  // Fetch all folders on dashboard mount (and set the default one)
  const lastAccountId = React.useRef<string | null>(null);
  React.useEffect(() => {
    if (currentAccount?.accountId !== lastAccountId.current) {
      lastAccountId.current = currentAccount?.accountId ?? "";
      loadFolders();
    }
  }, [currentAccount?.accountId]);

  const { hasPermission: hasPermissionToUpload } = usePlanPermissions(SublyPlan.Pro);
  const [showUploadOverlay, setShowUploadOverlay] = React.useState(false);

  const canShowUploadOverlay = React.useMemo(() => {
    const isInAllMedia = selectedFolderId === VirtualFolderId.All;
    const isInRealFolder =
      Boolean(selectedFolderId) &&
      selectedFolderId !== VirtualFolderId.All &&
      selectedFolderId !== VirtualFolderId.AllSharedWithMe &&
      selectedFolderId !== VirtualFolderId.AllShared &&
      selectedFolderId !== VirtualFolderId.AllPrivate;

    return hasPermissionToUpload && (isInAllMedia || isInRealFolder);
  }, [hasPermissionToUpload, selectedFolderId]);

  const handleDragEnter = (e: React.DragEvent) => {
    // make sure we're dragging a file
    const dt = e && e.dataTransfer;
    const isFile = dt && dt.types && dt.types.length == 1 && dt.types[0] == "Files";
    if (isFile) {
      // and, if so, show the overlay
      setShowUploadOverlay(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent) => {
    if (e.relatedTarget && e.relatedTarget instanceof Node && !e.currentTarget.contains(e.relatedTarget)) {
      setShowUploadOverlay(false);
    }
  };
  const handleWindowDragLeave = (e: DragEvent) => {
    if (!e.relatedTarget || !(e.relatedTarget instanceof Node)) {
      setShowUploadOverlay(false);
    }
  };
  const handleMouseMove = (e: React.MouseEvent) => {
    // Hide upload overlay if mouse moves without left button pressed, as handleDragLeave may not always fire.
    const leftClickIsPressed = e.buttons === 1;
    if (!leftClickIsPressed && showUploadOverlay) setShowUploadOverlay(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setShowUploadOverlay(false);
  };

  // This useEffect hook sets a warning message when the user tries to leave the page during an upload.
  React.useEffect(() => {
    window.onbeforeunload = () => (uploadQuery.isUploading ? "show warning" : null);
    window.addEventListener("dragleave", handleWindowDragLeave);
    return () => {
      window.removeEventListener("dragleave", handleWindowDragLeave);
    };
  }, []);

  return (
    <NavProvider>
      <div className="tw-flex tw-flex-row">
        <DashboardSidebar />
        <main
          className="tw-relative tw-flex tw-h-screen tw-max-h-screen tw-flex-grow tw-flex-col tw-overflow-auto tw-scroll-smooth"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onMouseMove={handleMouseMove}
          onDrop={handleDrop}
        >
          <TopBannerContainer>
            <div className="tw-flex tw-h-full tw-flex-col">
              <Outlet />
            </div>
          </TopBannerContainer>
          {canShowUploadOverlay && <DashboardDropzone show={showUploadOverlay} className="tw-absolute tw-inset-0" />}
        </main>
      </div>
      <UploadQueue />
    </NavProvider>
  );
};
