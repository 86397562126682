import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getAccountTeam } from "@frontend/api/account.service";
import { getCustomer } from "@frontend/api/billing.service";
import { notificationError } from "@frontend/components/notification";
import { setUserSettings } from "@frontend/config/settings/settings.service";
import { DASHBOARD_PATH } from "@frontend/routes";

import { useAccounts } from "@core/hooks/use-accounts";
import { authStore } from "@core/state/auth/auth.store";
import { editorStateRepository } from "@core/state/editor/editor.state";

export const OpenWorkspacePage: React.FC = () => {
  const { workspaceId = "" } = useParams();
  const { accounts } = useAccounts();
  const { currentAccount } = useAccounts();
  const navigate = useNavigate();

  React.useEffect(() => {
    // Redirect straight to the dashboard if opening active workspace
    if (workspaceId !== currentAccount?.accountId) {
      navigate(DASHBOARD_PATH); // redirect
    }

    // Set workspaceId and reload to dashboard if authorized to view workspace
    const workspaceIds = accounts.map((account) => account.accountId);
    const hasAccess = workspaceIds.includes(workspaceId);
    if (hasAccess) {
      setUserSettings({ workspaceId });
      window.location.href = DASHBOARD_PATH; // reload and redirect
      return;
    }

    // Redirect to dashboard if unauthorized and current active workspace is first valid workspace
    if (workspaceIds[0] === currentAccount?.accountId) {
      navigate(DASHBOARD_PATH); // redirect
      notificationError("You do not have access to this workspace.");
      return;
    }

    // Redirect to first valid workspace and show error if unauthorized
    (async () => {
      editorStateRepository.resetState();
      authStore.setAccountId(workspaceIds[0]);
      setUserSettings({ workspaceId: workspaceIds[0] });

      await getCustomer({ force: true });
      await getAccountTeam();

      navigate(DASHBOARD_PATH); // redirect
      notificationError("You do not have access to this workspace.");
    })();
  }, []);

  return <div></div>;
};
