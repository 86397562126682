import axios from "axios";

import { notificationSuccess, notificationWarning } from "@frontend/components/notification";
import config from "@frontend/config";
import { getAccountId } from "@frontend/config/settings/settings.service";

import { Invitation } from "@core/interfaces/account";
import { accountQuery, accountStore } from "@core/state/account";
import { authQuery } from "@core/state/auth/auth.query";
import { authStore } from "@core/state/auth/auth.store";
import { RoleName } from "@getsubly/common";

import { addAccountToStore, getAccountTeam } from "./account.service";
import { getAccessToken } from "./auth.service";
import { handleError } from "./handle-error";

const baseURL = `${config.apiUrl}/api/v1`;

interface InviteMembersResponse {
  invitations: Invitation[];
}
export const inviteMembers = async (invites: Invitation[]): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) return;

  try {
    await axios.post<InviteMembersResponse>(
      `/${accountId}/invite`,
      { invites },
      { baseURL: baseURL, headers: { "x-access-token": await getAccessToken() } }
    );

    getAccountTeam();
  } catch (error) {
    handleError(error);
    throw new Error(error);
  }
};

export const updateInvitationRole = async (id: string, role: RoleName): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(
      `/${accountId}/invitation/${id}`,
      { role },
      {
        baseURL: baseURL,
        headers: { "x-access-token": await getAccessToken() }
      }
    );

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const invitations = accountTeam.invitations?.map((i) => {
        if (i.id === id) {
          return { ...i, role };
        }

        return i;
      });

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, invitations }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const deleteInvitation = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.delete(`/${accountId}/invitation/${id}`, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    const accountTeam = accountQuery.getValue().accountTeam;
    if (accountTeam) {
      const invitations = accountTeam.invitations?.filter((i) => i.id !== id);

      accountStore.update((s) => ({
        ...s,
        accountTeam: { ...accountTeam, invitations }
      }));
    } else {
      getAccountTeam();
    }
  } catch (error) {
    handleError(error);
  }
};

export const resendInvitation = async (id: string): Promise<void> => {
  const accountId = getAccountId();

  if (!accountId) {
    return;
  }

  try {
    await axios.put(`/${accountId}/invitation/${id}/resend`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });
  } catch (error) {
    handleError(error);
  }
};

export const acceptInvitation = async (id: string, accountName: string): Promise<void> => {
  try {
    const { data } = await axios.put(`/invitation/${id}/accept`, null, {
      baseURL: baseURL,
      headers: { "x-access-token": await getAccessToken() }
    });

    addAccountToStore(data.joinedAccount);

    if (authQuery.user?.invitations?.length) {
      const invitations = authQuery.user.invitations.filter((i) => i.id !== id);
      authStore.update({ user: { ...authQuery.user, invitations } });
    }
    if (!data.hasAvailableSeats) {
      notificationWarning("You were switched to Viewer mode because there were no more seats available.");
    }

    notificationSuccess(`You now have access to ${accountName} workspace!`);
  } catch (error) {
    handleError(error);
  }
};
